import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import { consoleInfoLog } from 'App/Helpers/consoleInfoLog';
import hasAdElement from 'App/Helpers/hasAdElement';
import { notify } from 'App/React/_UI/WarningToaster';
import { useConsentScreen } from 'App/Game/hooks';
import { capitalize } from 'App/helpers';

const AdServiceContext = React.createContext(null);
export const useAdContext = () => useContext(AdServiceContext);

/**
 * Ad service provider
 *
 * @param adsConfig - object (required) { provider: string, sdkSrc: object, rewardMap: object }
 * @param gameGA - object (required) - initialized Game Analytics instance
 * @param ga - object (required) - initialized Google Analytics instance
 * @param children - React.Node || React.Element (required)
 */
const AdProvider = ({ adsConfig, gameGA, ga, children }) => {
    const { provider, sdkSrc, rewardMap } = adsConfig;
    const [adProvInit, setAdProvInit] = useState(false);
    const [level, setLevel] = useState(null);
    const [rewardType, setRewardType] = useState('');
    const [rewardReceived, setRewardReceived] = useState(false);
    const [vliRewardGranted, setVliRewardGranted] = useState(false);
    const [vliRewardClosed, setVliRewardClosed] = useState(false);
    const [vliRewardEmpty, setVliRewardEmpty] = useState(false);
    const [adinplayRewardFailed, setAdinplayRewardFailed] = useState('');

    useEffect(() => {
        if (provider !== 'dev') {
            provider === 'adinplay' && initAdinplayAdProvider();
            provider === 'vli' && initVliAdProvider();

            gameGA.sendDesignEvent(`Ad:ProviderName:${capitalize(provider)}`);
            ga.basicGaEvent('ad', `ad_provider_name_${provider}`);
        } else {
            consoleInfoLog('Ad Service DEV mode');
        }
    }, []);

    const consent = useConsentScreen(provider, gameGA, ga);

    useEffect(() => {
        if (rewardReceived) {
            gameGA.sendAdEvent(
                'RewardReceived',
                'RewardedVideo',
                provider,
                `${capitalize(rewardType)}`
            );
            gameGA.sendDesignEvent(
                `Ad:${capitalize(rewardType)}${capitalize(provider)}Granted:Level`,
                level
            );
            ga.basicGaEvent('ad', `${rewardType}_${provider}_granted`, level);
            setRewardType('');
        }
    }, [rewardReceived]);

    useEffect(() => {
        if (vliRewardGranted && vliRewardClosed) {
            setRewardReceived(true);
            setVliRewardClosed(false);
            setVliRewardGranted(false);
        }
        if (vliRewardClosed && !vliRewardGranted) {
            gameGA.sendAdEvent(
                'FailedShow',
                'RewardedVideo',
                provider,
                `${capitalize(rewardType)}`,
                { errorType: 'RewardClosed' }
            );
            gameGA.sendDesignEvent(
                `Ad:${capitalize(rewardType)}VLIClosed:Level`,
                level
            );
            ga.basicGaEvent('ad', `vli_${rewardType}_closed`, level);

            setRewardType('');
            setVliRewardClosed(false);
        }
    }, [vliRewardClosed, vliRewardGranted]);

    useEffect(() => {
        if (vliRewardEmpty) {
            gameGA.sendAdEvent(
                'FailedShow',
                'RewardedVideo',
                provider,
                `${capitalize(rewardType)}`,
                { errorType: 'RewardEmpty' }
            );
            gameGA.sendDesignEvent(
                `Ad:${capitalize(rewardType)}VLIEmpty:Level`,
                level
            );
            ga.basicGaEvent('ad', `vli_${rewardType}_empty`, level);

            setVliRewardEmpty(false);
        }
    }, [vliRewardEmpty]);

    useEffect(() => {
        if (adinplayRewardFailed) {
            gameGA.sendAdEvent(
                'FailedShow',
                'RewardedVideo',
                provider,
                `${capitalize(rewardType)}`,
                { errorType: `Reward${capitalize(adinplayRewardFailed)}` }
            );
            gameGA.sendDesignEvent(
                `Ad:${capitalize(rewardType)}Adinplay${capitalize(adinplayRewardFailed)}:Level`,
                level
            );
            ga.basicGaEvent(
                'ad',
                `adinplay_${rewardType}_${adinplayRewardFailed}`,
                level
            );

            setAdinplayRewardFailed('');
            setRewardType('');
        }
    }, [adinplayRewardFailed]);

    /**
     * shows
     */
    const showWarning = () => {
        notify('There is no ads for you right now, try again later');
    };

    /**
     * initialize Adinplay ad provider
     */
    const initAdinplayAdProvider = () => {
        consoleInfoLog('start Init Adinplay Ad Service', '#D2FBD0', '#094205');
        // Initializes a global aiptag.cmd function
        window.aiptag = window.aiptag || { cmd: [] };
        aiptag.cmd.display = aiptag.cmd.display || [];
        aiptag.cmd.player = aiptag.cmd.player || [];
        // CMP tool settings (gdpr consent modal)
        aiptag.cmp = {
            show: true,
            button: false,
        };
        setAdProvInit(true);
        // Initialize the ad video player
        aiptag.cmd.player.push(function () {
            aiptag.adplayer = new aipPlayer({
                AD_WIDTH: 960,
                AD_HEIGHT: 540,
                AD_DISPLAY: 'fullscreen', //default, fullscreen, center, fill
                LOADING_TEXT: 'loading advertisement',
                AIP_REWARDEDGRANTED: function () {
                    window.focus();
                    setRewardReceived(true);
                },
                AIP_REWARDEDCOMPLETE: function (evt) {
                    // evt can be: timeout, empty, unsupported or closed
                    window.focus();
                    setAdinplayRewardFailed(evt);

                    if (evt !== 'closed') showWarning();
                },
            });
        });
    };

    /**
     * setup config, event listeners and initialize vli rewarded ad by placement id
     *
     * @param id - string(required) ad placement id
     */
    const setupVliReward = (id) => {
        vitag.rewardedConfig[id] = vitag.rewardedConfig[id] || {};

        // Trigger the event when receiving a reward here (when conditions for receiving a reward are met)
        vitag.rewardedConfig[id].onRewarded = function () {
            window.focus();
            setVliRewardGranted(true);
        };
        // Trigger the event when the user closes the ad (not ended)
        vitag.rewardedConfig[id].onClose = function () {
            window.focus();
            setVliRewardClosed(true);
        };
        // Trigger the event when there are no ads available
        vitag.rewardedConfig[id].onAdEmpty = function () {
            window.focus();
            showWarning();
            setVliRewardEmpty(true);
        };
        // init reward ad placement
        (vitag.Init = window.vitag.Init || []).push(function () {
            viAPItag.initRewarded(id);
        });
    };

    /**
     * initialize VLI ad provider
     */
    const initVliAdProvider = () => {
        try {
            consoleInfoLog(`start Init VLI Ad Service`, '#D2FBD0', '#094205');
            // init vli ad provider
            window.vitag = window.vitag || {};
            vitag = vitag || {};
            vitag.gdprShowConsentToolButton = false;
            vitag.rewardedConfig = vitag.rewardedConfig || {};
            // setup all rewarded video configs
            Object.values(rewardMap).map((rewardId) =>
                setupVliReward(rewardId)
            );

            setAdProvInit(true);
        } catch (e) {
            gameGA.sendErrorEvent(
                'Error',
                'Failed to initialize VLI ad provider'
            );
            ga.basicGaEvent('error', 'vli_init_failed');
        }
    };

    /**
     * Show small side ad video banner and send event to game analytics
     */
    const showSideVideo = (bannerElement) => {
        if (provider === 'vli') {
            const videoId = bannerElement
                .querySelector('div')
                .getAttribute('data-ad-slot');

            (vitag.Init = window.vitag.Init || []).push(function () {
                viAPItag.initPowerInstream(videoId);
            });

            // check if ad container has video ad element after call showSideVideo method and send event to analytics
            setTimeout(() => {
                const gaEventName = bannerElement.getAttribute('data-ga-id');

                if (hasAdElement(bannerElement, 'POWER')) {
                    gameGA.sendAdEvent('Show', 'Video', provider, gaEventName);
                    gameGA.sendDesignEvent('Ad:ShowSideVideoVli');
                    ga.basicGaEvent('ad', 'ad_show_side-video_vli');
                } else {
                    gameGA.sendAdEvent(
                        'FailedShow',
                        'Video',
                        provider,
                        gaEventName,
                        {
                            errorType: 'CallFailed',
                        }
                    );
                    gameGA.sendErrorEvent(
                        'Warning',
                        `Failed to show ${gaEventName}`
                    );
                    ga.basicGaEvent('error', `fail_${gaEventName}`);
                }
            }, 2500);
        }
    };

    /**
     * Show rewarded ad video or send error event to game analytics
     */
    const showRewardedVideo = (rewardType, level) => {
        setLevel(level);
        setRewardType(rewardType);

        if (provider === 'dev') {
            setTimeout(() => {
                setRewardReceived(true); // for dev mode - always receive reward
            }, 100);
            return;
        }

        gameGA.sendAdEvent(
            'Show',
            'RewardedVideo',
            provider,
            `${capitalize(rewardType)}`
        );
        gameGA.sendDesignEvent(
            `Ad:CallReward${capitalize(provider)}:${capitalize(rewardType)}:Level`,
            level
        );
        ga.basicGaEvent('ad', `call_${rewardType}_${provider}`, level);

        if (provider === 'adinplay') {
            if (typeof aiptag.adplayer !== 'undefined') {
                aiptag.cmd.player.push(function () {
                    aiptag.adplayer.startRewardedAd({
                        subid: rewardType,
                        preload: false,
                        showLoading: true,
                    });
                });
            } else {
                gameGA.sendAdEvent(
                    'FailedShow',
                    'RewardedVideo',
                    provider,
                    `${capitalize(rewardType)}`,
                    { errorType: 'CallFailed' }
                );
                gameGA.sendErrorEvent(
                    'Error',
                    `Failed to show Adinplay Reward video on level: ${level}`
                );
                ga.basicGaEvent(
                    'error',
                    `adinplay_${capitalize(rewardType)}_failed`,
                    level
                );
            }
        }
        if (provider === 'vli') {
            (vitag.Init = window.vitag.Init || []).push(function () {
                viAPItag.getRewardedAd(rewardMap[rewardType]);
            });
        }
    };

    /**
     * Set banner visibility
     *
     * @param refElement - <HTMLElement>(required)
     * @param visible - <HTMLElement>(boolean)
     */
    const setBannerVisible = (refElement, visible) => {
        if (provider !== 'dev' && refElement) {
            visible
                ? refElement.classList.add('visible')
                : refElement.classList.remove('visible');
        }
    };

    /**
     * Displays and update ad banner content
     *
     * @param bannerElement - <HTMLElement>(required)
     * @param level - number || undefined (required)
     * @param setVisible - boolean (optional)
     */
    const updateAd = (bannerElement, level, setVisible = true) => {
        if (!bannerElement || provider === 'dev') return;

        setVisible && setBannerVisible(bannerElement, true);

        const banner = bannerElement.querySelector('div');

        if (provider === 'adinplay') {
            aiptag.cmd.display.push(function () {
                aipDisplayTag.display(banner.id);
            });
        }
        if (provider === 'vli') {
            (vitag.Init = window.vitag.Init || []).push(function () {
                viAPItag.display(banner.getAttribute('data-ad-slot'));
            });
        }

        // check if ad container has ad element after call adUpdate method and send event to analytics
        setTimeout(
            (currentLevel) => {
                const gaEventName = bannerElement.getAttribute('data-ga-id');
                const hasAdIframe = hasAdElement(bannerElement, 'IFRAME');

                if (hasAdIframe) {
                    gameGA.sendAdEvent('Show', 'Banner', provider, gaEventName);
                    gameGA.sendDesignEvent(
                        `Ad:${gaEventName}${currentLevel !== undefined ? ':Level' : ''}`,
                        currentLevel !== undefined && currentLevel
                    );
                    ga.basicGaEvent(
                        'ad',
                        gaEventName,
                        currentLevel !== undefined && currentLevel
                    );
                } else {
                    gameGA.sendAdEvent(
                        'FailedShow',
                        'Banner',
                        provider,
                        gaEventName,
                        {
                            errorType: 'CallFailed',
                        }
                    );
                    gameGA.sendErrorEvent(
                        'Warning',
                        `${gaEventName} update failed${currentLevel !== undefined ? ` on level: ${currentLevel}` : ''}`
                    );
                    ga.basicGaEvent(
                        'error',
                        `fail_${gaEventName}`,
                        currentLevel !== undefined && currentLevel
                    );
                }
            },
            1900,
            level
        );
    };

    const methods = {
        consent,
        provider,
        adProvInit,
        rewardReceived,
        setRewardReceived,
        showSideVideo,
        showRewardedVideo,
        setBannerVisible,
        updateAd,
    };

    return (
        <>
            <Helmet>
                <script
                    type='text/javascript'
                    src={sdkSrc[provider]}
                    async
                    defer
                />
            </Helmet>
            <AdServiceContext.Provider value={methods}>
                {children}
            </AdServiceContext.Provider>
            <Toaster
                containerStyle={{ top: 200 }}
                gutter={-145}
                toastOptions={{
                    duration: 2000,
                    position: 'top-center',
                    style: {
                        background: '#FFC213',
                        color: '#ffffff',
                    },
                }}
            />
        </>
    );
};

export default AdProvider;
